import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import StaticPageContent from "components/StaticPageContent";

const PrivacyPolicy = ({ data }) => {
  const content = data?.privacyPolicy?.content;

  return (
    <Layout
      activeHeader
      seo={{
        title: "Polityka prywatności",
        // description: seo?.metaDesc || "",
      }}
    >
      <StaticPageContent content={content} />
    </Layout>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query {
    privacyPolicy: wpPage(id: { eq: "cG9zdDoxMA==" }) {
      content
      title
    }
  }
`;
