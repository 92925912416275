import "./styles.scss";

import React from "react";

const mainClass = "static-page-content";

const StaticPageContent = ({ content }) => {
  return (
    <section className={mainClass}>
      <div className="container container--small">
        <h1>Polityka prywatności</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </section>
  );
};

export default StaticPageContent;
